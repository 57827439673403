<template>
  <div class="invoice-details py-8">
    <InvoiceAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <div class="ma-4">
          <div class="mb-6">
            <v-row>
              <v-col cols="6" md="4">
                <ViewingTitle :title="displayName" />
              </v-col>
              <v-col cols="6" md="2">
                <div class="d-flex gap-1">
                  <v-btn
                    :loading="loading"
                    color="primary"
                    class="mr-4"
                    @click="bulkHandle()"
                    >Approve</v-btn
                  >
                  <DeleteOrRestore
                    v-if="hasDeletePermissions"
                    :model="invoice"
                    name="Invoice"
                    redirect="/maintenance/invoices"
                  />
                </div>
              </v-col>
            </v-row>

            <InvoiceForm class="invoice-details__form" :invoice="invoice" />
          </div>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Invoice Request
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import InvoiceAppbar from '@/views/Home/Maintenance/Invoices/components/InvoiceAppbar'
import InvoiceForm from '@/views/Home/Maintenance/Invoices/components/InvoiceForm'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import ViewingTitle from '@/components/common/ViewingTitle.vue'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore/index.vue'

export default {
  name: 'InvoiceDetails',

  components: {
    DeleteOrRestore,
    ViewingTitle,
    InvoiceAppbar,
    InvoiceForm,
    Loading,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      invoice: (state) => state.invoice.invoiceDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.invoice ? this.invoice.label : null
    },

    hasDeletePermissions() {
      return validatePermissions([PERMISSION.INVOICES_DELETE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getInvoiceDetails: 'invoice/getInvoiceDetails',
      bulkApproveInvoice: 'invoice/bulkApproveInvoice',
    }),

    ...mapMutations({
      clearInvoiceDetails: 'invoice/clearInvoiceDetails',
    }),

    async getInvoice() {
      this.loading = true
      await this.getInvoiceDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async bulkHandle() {
      if (!this.invoice?.id) return
      this.loading = true
      const formData = new FormData()
      formData.append('invoice_ids[]', this.invoice.id)

      await this.bulkApproveInvoice(formData)
        .then(() => {
          this.loading = false
          this.showSnackbar('Bulk successfully updated!')
          this.getInvoice()
        })
        .catch((err) => {
          this.loading = false
          this.showSnackbar(this.getValidationErrors(err), false)
        })
    },
  },

  created() {
    this.getInvoice()
  },

  destroyed() {
    this.clearInvoiceDetails()
  },

  watch: {
    $route() {
      this.getInvoice()
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
